<template>
	<div>
		<div class="search">
			<Form :label-width="80" label-colon inline>
				<FormItem label="分组名称">
					<Input v-model="search.name" placeholder="分组名称"></Input>
				</FormItem>
				<FormItem :label-width="0">
					<Button type="primary" @click="init()">搜索</Button>
				</FormItem>
				<FormItem :label-width="0">
					<Button @click="openEditModal()">新增</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table">
			<Table :columns="columns" :data="data" :loading="loading">
				<template slot="is_forward" slot-scope="{row}">
					<Tag :color="row.is_forward == 1 ? 'success' : 'default'">{{ row.is_forward == 1 ? '是' : '否' }}</Tag>
				</template>
				<template slot="operate" slot-scope="{row}">
					<!-- <Button @click="toUrl('/device/forward_log',{ device_id: row.device_id })">日志</Button> -->
					<Button @click="openEditModal(row)" style="margin-right: 12px;">编辑</Button>
					<Button @click="deleteData(row.id)">删除</Button>
				</template>
			</Table>
			<div class="ser-page right">
				<Page v-model="search.page" :total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-total show-sizer transfer @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
			</div>
		</div>
		
		<Modal v-model="editModal.show" :title="editModal.status ? '新增分组' : '编辑分组'" @on-cancel="cancelEditModal()" width="800">
			<Form :label-width="120" label-colon>
				<FormItem label="分组名称">
					<Input v-model="editModal.data.name"></Input>
				</FormItem>
				<FormItem label="是否转发">
					<Select v-model="editModal.data.is_forward" style="width:200px">
						<Option :value="0">否</Option>
						<Option :value="1">是</Option>
					</Select>
				</FormItem>
				<FormItem label="转发地址">
					<Input v-model="editModal.data.forward_addr"></Input>
				</FormItem>
				<FormItem label="备注">
					<Input v-model="editModal.data.remarks"></Input>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="cancelEditModal()">取消</Button>
				<Button type="primary" @click="editModalConfirm()">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	var _this, editData = {
		name: '',
		forward_addr: '',
		is_forward: 1,
		remarks: ''
	}
	export default {
		data() {
			return {
				search: {
					name: '',
					page: 1,
					pageSize: 15,
					total: 0
				},
				loading: false,
				data: [],
				columns: [
					{ title: '分组名称', key: 'name', tooltip: true },
					{ title: '转发地址', key: 'forward_addr', tooltip: true },
					{ title: '是否转发', slot: 'is_forward', width: 100 },
					{ title: '备注', key: 'remarks', tooltip: true },
					{ title: '创建时间', key: 'create_date', width: 160 },
					{ title: '操作', slot: 'operate' }
				],
				editModal: {
					show: false,
					status: true,
					data: JSON.parse(JSON.stringify(editData)),
				},
			}
		},
		created() {
			_this = this;
		},
		methods: {
			init() {
				var _this = this;
				this.loading = true;
				this.requestApi('/adm/get_device_forward_group_list',{ search: this.search }).then((res) => {
					if(parseInt(res.status) === 1){
						_this.loading = false;
						_this.data = res.data.data;
						_this.search.total = res.data.total;
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 切换页码
			pageChange(page) {
				this.search.page = page;
				this.init();
			},
			// 切换每页条数
			pageSizeChange(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			},
			// 新增/编辑 打开弹窗
			openEditModal(data){
				if(data){
					for(let index in this.editModal.data){
						if(index == 'is_forward') {
							this.editModal.data.is_forward = parseInt(data.is_forward);
						} else {
							this.editModal.data[index] = data[index];
						}
					}
					this.editModal.data.id = data.id;
					this.editModal.show = true;
				}else{
					this.editModal.show = true;
				}
			},
			// 新增/编辑 关闭弹窗
			cancelEditModal(){
				this.editModal.show = false;
				setTimeout(()=>{
					_this.editModal.data = JSON.parse(JSON.stringify(editData));
				},500);
			},
			// 新增/编辑 确认
			editModalConfirm(){
				var data = JSON.parse(JSON.stringify(this.editModal.data));
				// 是否填写分组名称
				if(! data.name) {
					return this.alertErr('请填写分组名称');
				}
				this.requestApi('/adm/edit_device_forward_group_confirm',{ data: data }).then((res) => {
					if(parseInt(res.status) === 1){
						_this.alertSucc('保存成功');
						_this.cancelEditModal();
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 删除 转发配置
			deleteData(id) {
				this.$Modal.confirm({
					title: '确认删除',
					content: '确认删除当前分组吗？删除分组会删除分组下设备的所有转发配置和记录，此操作将不可逆！',
					onOk: () => {
						_this.requestApi('/adm/del_device_forward_group', { id }).then((res) => {
							if(parseInt(res.status) === 1){
								_this.alertSucc('删除成功');
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						});
					}
				});
			},
		}
	}
</script>

<style scoped>
	.search {
		padding: 0 16px;
	}
	
	.table{
		padding: 16px;
	}
	
	.right{
		text-align: right;
	}
</style>